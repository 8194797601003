import { useEffect } from 'react';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { setDataLayer } from '@/hooks/use-gtm';

let tries = 0;
let timeout: null | NodeJS.Timeout = null;
const maxTries = 50;

export const TELIA_SCRIPT_ID = 'ace-telia-sdk';
const CLASSNAME = 'humany-widget-inactive';
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

async function activateWidget(widgetName: string) {
    if (tries === maxTries) {
        return;
    }
    tries++;

    try {
        const widget = window?.humany?.widgets?.find(widgetName);
        if (!widget) {
            timeout = setTimeout(() => activateWidget(widgetName), 250);
            return;
        }

        document.body.classList.remove(CLASSNAME);

        tries = 0;
    } catch (error) {
        console.error(error);
    }
}

export async function deactivateWidget(widgetName: string) {
    try {
        const widget = window?.humany?.widgets?.find(widgetName);
        if (widget && widget?.state !== 'deactivated') {
            document.body.classList.add(CLASSNAME);
        }
    } catch (error) {
        console.error(error);
    }
}

export async function addScriptTag({ widgetName, activate = true, delay }: { widgetName: string; activate?: boolean; delay?: number | null }) {
    if (document.getElementById(TELIA_SCRIPT_ID)) {
        if (activate) {
            activateWidget(widgetName);
        }
        return;
    }

    if (delay) {
        await sleep(delay);
    }

    const script = document.createElement('script');
    script.id = TELIA_SCRIPT_ID;
    script.src = '//wds.ace.teliacompany.com/wds/instances/JpnKjqJs/ACEWebSDK.min.js';
    script.async = true;

    script.onload = () => {
        if (activate) {
            activateWidget(widgetName);
        }
    };

    document.body.appendChild(script);
}

export const HumanyChatBot = () => {
    const { pageQuery, getSetting } = useGlobalQueries();
    const botInterface = getSetting('humany_settings')?.humany_ai_chatbot_widget_name || 'resurs-bot-v5';
    const widgetName = botInterface.toLocaleLowerCase().replace(/\s+/g, '-');

    const gtmChatbotCallback = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (target?.matches('div.humany-widget button')) {
            setDataLayer({
                action: 'click',
                category: 'chatbot',
                label: target?.innerText,
            });
        }
    };

    useEffect(() => {
        if (pageQuery?.data?.show_ai_chatbot) {
            addScriptTag({ widgetName });
        } else {
            deactivateWidget(widgetName);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [pageQuery]);

    useEffect(() => {
        document.body.addEventListener('click', gtmChatbotCallback);
        () => {
            document.body.removeEventListener('click', gtmChatbotCallback);
        };
    }, []);

    return <a href={`//resurs-bank.humany.net/${widgetName}`} />;
};
